<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>

      <!-- Title -->
      <v-card-title>
        <v-row justify="space-between" no-gutters>
          <span>{{ link.label }}</span>
          <v-chip label>{{ link.type }}</v-chip>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row no-gutters>

          <!-- Qr code img -->
          <v-col cols="12" md="8" offset-md="2">
            <v-row no-gutters justify="center">
              <vue-qr
                  :text="link.static_link"
                  :size="200"
                  logoSrc="/pictures/logo_QRcode_border.png"
                  :logoScale="0.3"
                  :correctLevel="3"
              />
              <vue-qr
                style="display: none;"
                :text="link.static_link"
                :size="500"
                logoSrc="/pictures/logo_QRcode_border.png"
                :logoScale="0.3"
                :correctLevel="3"
                :callback="qrCodeEnabled"
              />
            </v-row>
          </v-col>



          <!-- Static link -->
          <v-col cols="12" class="mt-2">
            <p>
              <span>{{ $t('links.labels.static_link') }} : </span>
              <span class="font-weight-bold">{{ link.static_link }}</span>
              <ButtonIconCopy :value="link.static_link" />
            </p>
          </v-col>

          <!-- Dynamic link -->
          <v-col cols="12">
            <span>{{ $t('links.labels.dynamic_link') }} : </span>
            <span class="font-weight-bold">{{ link.dynamic_link }}</span>
            <ButtonIconCopy :value="link.dynamic_link" />
          </v-col>

          <!-- Button close -->
          <v-col cols="12" class="mt-3">
            <v-row no-gutters justify="space-between">
              <v-btn @click="downloadQrCode">
                <v-icon small>fas fa-download</v-icon>
                <span class="ml-1">{{ $t('links.buttons.download') }}</span>
              </v-btn>
              <v-btn @click="close(false)">{{ $t('common.buttons.close') }}</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog"
import VueQr       from 'vue-qr'
import file        from "../../mixins/file";

export default {
  name: "LinkDetailsDialog",

  mixins: [dialogMixin],

  components: {
    ButtonIconCopy: () => import("@/components/Common/Buttons/ButtonIconCopy"),
    VueQr
  },

  data() {
    return {
      dataURI: null
    }
  },

  props: {
    link: {type: Object, default: () => null},
  },

  mounted() {
  },
  methods: {
    qrCodeEnabled(uri, id) {
      this.dataURI = uri;
    },

    downloadQrCode() {
      var byteString = atob(this.dataURI.split(',')[1]);
      var mimeString = this.dataURI.split(',')[0].split(':')[1].split(';')[0];
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      let filename = "";
      const a = new Blob([ab], {type: mimeString});
      const linkHTML = document.createElement('a')
      linkHTML.href = URL.createObjectURL(a);
      if (this.link.account)
        filename += this.link.account.name;
      if (this.link.product)
        filename += `_${this.link.product.name}`;
      if (filename === "")
        filename = "qr-code";
      filename += ".png";
      linkHTML.download = filename;
      linkHTML.click()
    }
  }
}
</script>

<style scoped>
.qr-code {
  background-color: #3F51B5 !important;
  width: 200px;
  height: 200px;
}
</style>
