export default {

  data() {
    return {
      uploadedImg: null,
      uploadedFile: null,
    }
  },

  methods: {
    async proceedPictureUpload(val) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("picture", val);

        this.$http.post(this.URL_API_FILE.UPLOAD_PICTURE, formData, {
          headers: {
            Authorization: "Bearer " + this.$session.get('jwt'),
            'content-type': 'multipart/form-data'
          }
        })
        .then(res => {
          this.uploadedImg = res.data;
          return resolve(res.data);
        })
        .catch(err => {
          this.uploadedImg = null;
          return reject(err);
        })
      });
    },

    proceedFileUpload(val) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("file", val);

        this.$http.post(this.URL_API_FILE.UPLOAD_FILE, formData, {
          headers: {
            Authorization: "Bearer " + this.$session.get('jwt'),
            'content-type': 'multipart/form-data'
          }
        })
        .then(res => {
          this.uploadedFile = res.data;
          return resolve(res.data);
        })
        .catch(err => {
          this.uploadedFile = null;
          return reject(err);
        })
      });
    }
  }
}